// ChooseLocation.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Customer = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [customers, setCustomers] = useState([]); // État pour stocker les données des clients
  const [loading, setLoading] = useState(true);   // Pour afficher un état de chargement
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(0); // Page actuelle
  const [totalPages, setTotalPages] = useState(1);   // Total des pages
  const limit = 10; // Nombre de clients par page

  // Requête API pour obtenir les clients avec pagination
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getAdminUser}/?role=ROLE_CLIENT&page=${currentPage}&limit=${limit}`
        ); // L'URL inclut la page et la limite

        setCustomers(response.data.users); // Assurez-vous que 'customers' correspond aux données renvoyées par votre API
        setTotalPages(response.data.totalPages || 1); // Le nombre total de pages si disponible dans la réponse
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors du chargement des clients:", error);
        setLoading(false);
      }
    };

    fetchCustomers();
  }, [currentPage]); // Recharger les données à chaque changement de page

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }};


  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select>
              <option value="">Name</option>
              <option value="Name">name</option>
            </select>
            <select>
              <option value="">Contains</option>
              <option value="Name">Contains</option>
            </select>
            <select>
              <option value="">All Filters</option>
              <option value="Name">All Filters</option>
            </select>
            <select>
              <option value="">All Tags</option>
              <option value="Name">All Tags</option>
            </select>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Ajouter un client
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Image</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Registration Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {customers?.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>
                      <img src={image} alt="FlySpa" />
                    </td>
                    <td>{customer.firstname} {customer.lastname}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.email}</td>
                    <td>{customer.registredDate}</td>
                    <td className={customer?.status?.toLowerCase()}>
                      {customer.status}
                    </td>
                    <td>
                      <a href="#" className="edit">
                        Edit
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">Aucun client trouvé.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Ajouter un client</h2>
        <button onClick={closeModal}>close</button>
        <div>I am a modal</div>
        
      </Modal>
    </>
  );
};

export default Customer;
