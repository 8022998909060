export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/api';

export const endpoints = {
  loginUrl: '/login_check',
  refreshToken: '/token/refresh',
  getProfil: '/client/profil',
  invite: '/admin/invite',
  register: '/client/register',
  verify: '/client/verify/otp',
  getAdminUser: '/admin/users',
  
};