import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";

import "./App.css";
import Login from "./components/Login";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Customer from "./components/Customer";
const ROLES = {
  Client: "ROLE_CLIENT",
  Editor: "ROLE_USER",
  Admin: "ROLE_ADMIN",
};
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="" element={<Dashboard />} />
            <Route path="Customer" element={<Customer />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
